import React from 'react';

let peer = null;
let conn = null;
let call = null;

function createMarkup(value) {
  return { __html: value };
}

const StatusLogger = props => {
  console.log(`${props.name}: ${props.status}`);
  return (
    <div style={{ width: '50%', minWidth: '50%', margin: '10px' }}>
      <h2>{props.name}</h2>
      <div dangerouslySetInnerHTML={createMarkup(props.status)} />
    </div>
  );
};

class MeetingUI extends React.Component {
  constructor(props) {
    super(props);
    const { localID, remoteID } = props;
    this.state = {
      localStatus: '<p>idle..</p>',
      remoteStatus: '<p>idle..</p>',
      peerOpen: false,
      activeCalls: [],
      chatText: '',
      messageText: '',
      callID: remoteID,
      localID,
      remoteID
    };

    this.handleChatText = this.handleChatText.bind(this);
    this.handleChatSubmit = this.handleChatSubmit.bind(this);

    this.handleCallID = this.handleCallID.bind(this);
    this.handleCallIDSubmit = this.handleCallIDSubmit.bind(this);
  }

  openPeer = () => {
    if (typeof window !== `undefined`) {
      peer = window.peer;
      peer.on('open', id => {
        console.log(`My peer ID is: ${id}`);
        this.setState(state => ({
          localStatus: `${state.localStatus}<p>Local peer ID is ${id}</p>`,
          peerOpen: true
        }));
      });

      peer.on('error', err => {
        console.log(`PeerJS error: ${err}`);
        this.setState(state => ({
          localStatus: `${state.localStatus}<p>PeerJS error: ${err}</p>`,
          peerOpen: false
        }));
      });
    }
  };

  makeCall = () => {
    if (!this.state.peerOpen) {
      this.openPeer();
    }
    this.setState(state => ({
      localStatus: `${state.localStatus}<p>Starting call to ${state.remoteID}</p>`
    }));

    conn = peer.connect(this.state.remoteID);

    this.setState(state => ({
      localStatus: `${state.localStatus}<p>Waiting for response from ${state.remoteID}</p>`
    }));

    conn.on('open', () => {
      this.setState(state => ({
        localStatus: `${state.localStatus}<p>connection open, sending greeting...</p>`
      }));
      conn.send(`Hello from ${this.state.localID}`);
    });

    peer.on('connection', conn => {
      conn.on('data', data => {
        this.setState(state => ({
          remoteStatus: `${state.remoteStatus}<p>${state.remoteID}: ${data}</p>`
        }));
      });

      conn.on('open', () => {
        this.setState(state => ({
          localStatus: `${state.localStatus}<p>connection open, sending greeting...</p>`
        }));
        conn.send(`Hello from ${this.state.localID}`);
      });
    });
  };

  handleChatText(e) {
    this.setState({ messageText: e.target.value });
  }

  handleChatSubmit(e) {
    e.eventPreventDefault();
    if (this.state.messageText.length === 0) {
      return;
    }
    conn.send(`${this.state.messageText}`);
    this.setState(state => ({
      chatText: `${state.chatText}<p>${state.localID}:${tate.messageText}</p>`
    }));
    this.setState({ messageText: '' });
  }

  handleCallID(e) {
    this.setState({ callID: e.target.value });
  }

  handleCallIDSubmit(e) {
    e.eventPreventDefault();
    if (this.state.callID.length === 0) {
      return;
    }
    const connectCall = peer.connect(this.state.callID);
    this.setState(state => ({
      activeCalls: state.activeCalls.push(this.state.callID)
    }));
    this.setState({ callID: '' });
  }

  sendMessage = () => {
    const message = document.querySelector('#messageText').value;
    conn.send(`${message}`);
  };

  render() {
    const { localID, remoteID } = this.props;

    return (
      <div>
        <h1>Hello you!</h1>
        <p>
          Welcome to your personal and (hopefully) private video conferencing
          system.
        </p>
        <p>Your personal meeting ID is {localID}</p>
        <p>Please enter a meeting id to connect and start a meeeting</p>
        <form id="chatRoom" onSubmit={this.handleChatSubmit}>
          <label htmlFor="messageText">Enter text to send</label>
          <input
            id="messageText"
            type="text"
            onChange={this.handleChatText}
            value={this.state.messageText}
          />
          <button>Send</button>
        </form>
        <form id="contacts" onSubmit={this.handleCallIDSubmit}>
          <input
            id="callID"
            type="text"
            onChange={this.handleCallID}
            value={this.state.callID}
          />
          <button>Call</button>
        </form>
        <button onClick={this.makeCall}>Call default User / Init open</button>
        <div id="messageLogs" style={{ display: 'flex' }}>
          <StatusLogger name="Local" status={this.state.localStatus} />
          <StatusLogger name="Remote" status={this.state.remoteStatus} />
        </div>
      </div>
    );
  }
}

export default MeetingUI;
