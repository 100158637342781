import React from 'react';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';

import Layout from '../components/layout';
import MeetingUI from '../components/meeting-ui';
import SEO from '../components/seo';

const localID = 'tamsaunders';
const remoteID = 'timsaunders';

const MeetingPage = () => (
  <Layout>
    <Helmet>
      <script type="text/javascript" src="/scripts/peerjs/dist/peerjs.min.js" />
      <script>
        {`
            setTimeout(() => {
              window.peer = new Peer('${localID}', { debug: 3 });
              console.log(window.peer);
            }, 1000)
          `}
      </script>
    </Helmet>
    <SEO title="Meeting" />
    <MeetingUI localID={localID} remoteID={remoteID} />
    <Link to="/about/">Find out more about this service</Link>
  </Layout>
);

export default MeetingPage;
